body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width:600px) {
  .tox-toolbar-overlord {
    display:flex;
  }
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Tiptap Editor Styles */
.ProseMirror {
    > * + * {
        margin-top: 0.75em;
    }

    p {
        /* margin: 0; */
        line-height: 1.5;
    }

    ul,
    ol {
        padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin: 1em 0 0.5em;
    }

    code {
        background-color: rgba(97, 97, 97, 0.1);
        color: #616161;
        padding: 0.2em;
        border-radius: 3px;
    }

    pre {
        background: #0D0D0D;
        color: #FFF;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    blockquote {
        padding-left: 1rem;
        border-left: 3px solid rgba(13, 13, 13, 0.1);
        color: rgba(13, 13, 13, 0.8);
        margin: 1em 0;
    }

    hr {
        border: none;
        border-top: 2px solid rgba(13, 13, 13, 0.1);
        margin: 2rem 0;
    }

    a {
        color: #2196F3;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 1em 0;
    }
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
