.articleTable {
  height:800px;
}

.media-enter {
  opacity: 0;
}
.media-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.media-exit {
  opacity: 1;
}
.media-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.ai-headline-picker {

}
.ai-headline-picker h2 {
  font-size:16px;
  margin:0;
  padding:0;
}

.ai-headline-picker ul li {
  margin:5px 0 ;
  padding:5px 10px;
  border-radius:10px;
  border:1px solid #eee;
  transition:all 0.2s ease-in-out;
}
.ai-headline-picker ul li:hover {
  background-color:#eee;
  cursor:pointer;
}

.ai-headline-picker ul {
  margin: 0;
  padding:0;
  list-style: none;
}
.article-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 10px;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.ai-headline-picker-loading {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.article-loader__heading {
    font-size: 20px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    margin-bottom: 10px;
}

.article-loader__progress {
    width:30px;
    height:30px;
    display:block;
}